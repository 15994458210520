import React from 'react'
import Layout from '../components/Layout.jsx'
// import GalleryBg from "../images/gallery_bg.jpg"
// import Gallery1 from "../images/gallery_1.jpg"
// import Gallery2 from "../images/gallery_2.jpg"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import Carousel from 'react-bootstrap/Carousel';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import "bootstrap/dist/js/bootstrap.min.js";

import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import BGGallery from "../images/bg_galerija.jpg"



const Gallery = () => {

  const pageDataQuery = graphql`
    query MyQueryFoo {
      contentfulFotoGalerija {
        galerijaSlike {
          url
        }
      }
    }
  `
  const data = useStaticQuery(pageDataQuery);
  //console.log(JSON.stringify(data, null, 4));

  return (
    <Layout activeTab="Gallery" bgImage={BGGallery}>
      <CarouselContainer>
        <Carousel>
          {
            data.contentfulFotoGalerija.galerijaSlike.map((item, index) => {
              return (
                <ImageContainer style={{ border: '2px solid black', boxShadow: '2px 3px 7px 0px rgba(115,115,115,1)' }}>
                  <img src={item.url} />
                </ImageContainer>
              )
            })
          }
          {/* <ImageContainer style={{ border: '2px solid black', boxShadow: '2px 3px 7px 0px rgba(115,115,115,1)' }}>
            <img src={data.contentfulInfoONama.instruktor1slika.url} />
          </ImageContainer>
          <ImageContainer>
            <img src={data.contentfulInfoONama.instruktor1slika.url} />
          </ImageContainer> */}
        </Carousel>

      </CarouselContainer>
    </Layout>
  )
}

export default Gallery

const ImageContainer = styled.div`
  border: 2px solid black;
  box-shadow: 2px 3px 7px 0px rgba(115,115,115,1);
`

const CarouselContainer = styled.div`
/* margin-left: auto;
margin-right: auto;
width: 48rem;
img{
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    width: auto;

} */

padding: 10px;
width: 100%;
height: 100%;

`
